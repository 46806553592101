import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PictureHandlerService {

  private allPictures;


  constructor() {
    this.allPictures=[];
    this.loadPictures();
  }



  public getAllPictures(){
    return this.allPictures;
  }

  public getPicturesByCategory(category){
    let categoryPictures = [];

    if(category =='color'){
      return this.getColorPictures();
    }
    this.allPictures.forEach(element => {
      if(element.category == category){
        categoryPictures.push(element);
      }
    });
    return categoryPictures;
  }

  public getPicturesByCategoryAndArchetype(category,archetype){
    let categoryAndArchetypePictures = [];
    this.allPictures.forEach(element => {
      if(element.category == category && element.archetype == archetype){
        categoryAndArchetypePictures.push(element);
      }
    });
    return categoryAndArchetypePictures;
  }

  private createPicture(url){
    const attributes = url.split('/');
    const name = JSON.stringify(attributes[4]).split('.');
    let picture = {
      name:name[0].slice(1),
      url:url,
      archetype:attributes[2],
      category:attributes[3]
    }
    return picture;
  }

  private getColorPictures(){

    let colors= [];
    colors.push(
      {
      name:'blau',
      url:'assets/archetypes-pictures/schoepfer/color/blau.jpg',
      archetype:['schoepfer','held'],
      category:'color'
    })
    colors.push(
      {
      name:'gruen',
      url:'assets/archetypes-pictures/beschuetzer-fuersorgender/color/gruen.jpg',
      archetype:['beschuetzer-fuersorgender','entdecker'],
      category:'color'
    })
    colors.push(
      {
      name:'rot',
      url:'assets/archetypes-pictures/herrscher/color/rot.jpg',
      archetype:['herrscher','rebell','liebhaber'],
      category:'color'
    })
    colors.push(
      {
      name:'schwarz',
      url:'assets/archetypes-pictures/held/color/schwarz.jpg',
      archetype:['herrscher','held'],
      category:'color'
    })
    colors.push(
      {
      name:'violett',
      url:'assets/archetypes-pictures/magier/color/violett.jpg',
      archetype:['magier'],
      category:'color'
    })
    colors.push(
      {
      name:'weiss',
      url:'assets/archetypes-pictures/weise/color/weiss.jpg',
      archetype:['durchschnittstyp','unschuldige-ehrliche','weise'],
      category:'color'
    })
    colors.push(
      {
      name:'grau',
      url:'assets/archetypes-pictures/durchschnittstyp/color/grau.jpg',
      archetype:['durchschnittstyp'],
      category:'color'
    })
    colors.push(
      {
      name:'braun',
      url:'assets/archetypes-pictures/entdecker/color/braun.jpg',
      archetype:['durchschnittstyp','entdecker'],
      category:'color'
    })
    colors.push(
      {
      name:'rosa',
      url:'assets/archetypes-pictures/liebhaber/color/rosa.jpg',
      archetype:['liebhaber'],
      category:'color'
    })
    colors.push(
      {
      name:'gelb',
      url:'assets/archetypes-pictures/narr-spassvogel/color/gelb.jpg',
      archetype:['narr-spassvogel'],
      category:'color'
    })

    return colors;
  }

  private loadPictures(){

    this.allPictures.push(this.createPicture('assets/archetypes-pictures/beschuetzer-fuersorgender/animal/delfin.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/beschuetzer-fuersorgender/brand/drk.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/beschuetzer-fuersorgender/car/van.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/beschuetzer-fuersorgender/color/gruen.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/beschuetzer-fuersorgender/job/anwalt.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/beschuetzer-fuersorgender/person/angela_merkel.jpg'));

    this.allPictures.push(this.createPicture('assets/archetypes-pictures/durchschnittstyp/animal/fisch.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/durchschnittstyp/brand/vw_logo.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/durchschnittstyp/car/vw_golf.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/durchschnittstyp/color/grau.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/durchschnittstyp/job/bankkaufmann.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/durchschnittstyp/person/kai_pflaume.jpg'));

    this.allPictures.push(this.createPicture('assets/archetypes-pictures/entdecker/animal/tiger.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/entdecker/brand/patagonia.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/entdecker/car/rakete.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/entdecker/color/braun.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/entdecker/job/astronaut.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/entdecker/person/albert_einstein.jpg'));
    
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/held/animal/wolf.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/held/brand/marvel.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/held/car/lamborghini.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/held/color/schwarz.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/held/job/fussballer.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/held/person/james_bond.jpg'));
    
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/herrscher/animal/loewe.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/herrscher/brand/rolex.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/herrscher/car/privatjet.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/herrscher/color/rot.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/herrscher/job/politiker.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/herrscher/person/napoleon.jpg'));
    
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/liebhaber/animal/koala.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/liebhaber/brand/merci.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/liebhaber/car/mercedes_c.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/liebhaber/color/rosa.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/liebhaber/job/koch.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/liebhaber/person/jude_law.jpg'));

    this.allPictures.push(this.createPicture('assets/archetypes-pictures/magier/animal/chamaeleon.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/magier/brand/disney.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/magier/car/mercedes_benz.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/magier/color/violett.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/magier/job/kuenstler.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/magier/person/harry_potter.jpg'));

    this.allPictures.push(this.createPicture('assets/archetypes-pictures/narr-spassvogel/animal/papagei.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/narr-spassvogel/brand/sat1.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/narr-spassvogel/car/citroen.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/narr-spassvogel/color/gelb.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/narr-spassvogel/job/comedian.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/narr-spassvogel/person/helge_schneider.jpg'));

    this.allPictures.push(this.createPicture('assets/archetypes-pictures/rebell/animal/katze.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/rebell/brand/converse.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/rebell/car/motorrad.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/rebell/color/rot.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/rebell/job/journalist.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/rebell/person/che_guevara.jpg'));
    
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/animal/biene.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/animal/storch.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/brand/apple.jpg'));
    //this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/brand/tesla.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/car/porsche_taycan.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/color/blau.jpg'));
    //this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/job/forscher.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/job/schriftsteller.jpg'));
    //this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/person/bill_gates.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/schoepfer/person/mozart.jpg'));

    this.allPictures.push(this.createPicture('assets/archetypes-pictures/unschuldige-ehrliche/animal/hummel.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/unschuldige-ehrliche/brand/coca_cola.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/unschuldige-ehrliche/car/ice.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/unschuldige-ehrliche/color/weiss.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/unschuldige-ehrliche/job/polizist.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/unschuldige-ehrliche/person/pippi_langstrumpf.jpg'));
    
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/weise/animal/eule.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/weise/brand/discovery_channel.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/weise/car/mercedes_fluegel.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/weise/color/weiss.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/weise/job/professor.jpg'));
    this.allPictures.push(this.createPicture('assets/archetypes-pictures/weise/person/dumbledore.jpg'));

  }
}
