import { Injectable } from '@angular/core';
import { PictureHandlerService } from './picture-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ArchetypeHandlerService {

  private archetypes;
  private pictures;

  public Archetype = Archetype;

  constructor(private pictureHandlerService:PictureHandlerService) {
    this.archetypes=[];
    this.pictures=[];
    this.loadArchetypes();
   }

  loadArchetypes(){
    this.archetypes.push(
      {
        name: 'Schoepfer',
        description: 'Der Schöpfer erschafft aus dem Durcheinander etwas Neues mit langfristigem Wert, er formt Sinn. Er setzt Visionen in die Tat um mit kreativen, schöpferischen Tätigkeiten.',
        kategorieBild: "assets\\cards-pictures\\bill_gates_card.jpg",
        attributes:['Selbstverwirklichung', 'Wissen', 'Inspiration', 'Schöpfung'

        ],
        pictures:[
          
        ],
        glasses:[
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Beschuetzer/Fuersorgende',
        description: 'Der Beschützer/Fürsorgender nimmt die Emotionen und Wünsche des Einzelnen wahr. Er hilft anderen, bietet Schutz und ein Gefühl von Sicherheit sowie Geborgenheit. Er opfert sich für andere auf. ',
        kategorieBild: "assets\\cards-pictures\\angela_merkel_card.jpg",
        attributes:['Fürsorge', 'Unterstützung', 'Verbundenheit'

        ],
        pictures:[
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Herrscher',
        description: 'Der Herrscher zielt auf Führung und Kontrolle, um ein harmonisches Umfeld zu schaffen. Er übernimmt Verantwortung und zeigt seine Regel- und Steuerungskompetenz.',
        kategorieBild: "assets\\cards-pictures\\napoleon_card.jpg",
        attributes:['Status', 'Macht', 'Kontrolle'

        ],
        pictures:[ 
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Held',
        description: 'Mit Mut und Kompetenz will er die Welt verbessern. Er ist durchsetzungsstark, hat überdurchschnittliche Kräfte und Fähigkeiten. Will macht/Einfluss haben und setzt sich für das ein, was in seinen Augen wirklich zählt.',
        kategorieBild: "assets\\cards-pictures\\james_bond_card.jpg",
        attributes:['Mut', 'Kompetenz', 'Einfluss'

        ],
        pictures:[ 
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Rebell',
        description: 'Der Rebell steht außerhalb der Gesellschaft und will alles verändern, was aus seiner Sicht nicht einwandfrei ist. Strebt nach Revolte/Revolution, oft getrieben durch frühere Verletzungen. ',
        kategorieBild: "assets\\cards-pictures\\che_guevara_card.jpg",
        attributes:['Ethik', 'Moral', 'Gerechtigkeit'

        ],
        pictures:[ 
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Magier',
        description: 'Er ist Meister des Wandels und lässt alle Wünsche wahr werden. Er ist Alchemist, der aus tiefstem Selbstverständnis heraus die Dinge zum Guten (aber auch zum Bösen) wandeln kann. Er entwickelt starke Visionen und kann Helden helfen, die Gesellschaft zu transformieren. Er fürchtet sich vor Unvorhergesehenem und möglichen negativen Konsequenzen seines Tuns. Er sucht nach Aufgaben/Herausforderungen, an denen er wachsen kann. ',
        kategorieBild: "assets\\cards-pictures\\harry_potter_card.jpg",
        attributes:['Inspiration', 'Glaube', 'Wandel', 'Präsenz'

        ],
        pictures:[ 
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Durchschnittstyp',
        description: '„Otto Normalverbraucher“ oder auch „Everyday Joe“ strebt nach Zugehörigkeit und will daher nicht anecken bzw. besonders auffallen. Seine Tugenden sind gewöhnlich und solide. Er will dazu gehören und akzeptiert werden. Auf ihn ist Verlass und er steht seinen Freunden, mit denen er eine tiefe Freundschaft führt, stets loyal zur Seite.',
        kategorieBild: "assets\\cards-pictures\\kai_pflaume_card.jpg",
        attributes:['Akzeptanz', 'Verbundenheit', 'Harmonie', 'Bodenständigkeit', 'Loyalität', 'Treue',
          'Verlässlichkeit'
          

        ],
        pictures:[ 
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Liebhaber',
        description: 'Er hat Angst vor dem Alleinsein und strebt nach Genuss sowie dem Glücklichsein in jeder Hinsicht. Glücklich ist der Liebhaber, wenn er eine gute Beziehung zu seinen Mitmenschen, seiner Arbeit und seinem gesamtem Umfeld hat. Er will seine Attraktivität steigern, sowie lieben und geliebt werden. ',
        kategorieBild: "assets\\cards-pictures\\jude_law_card.jpg",
        attributes:['Hingabe', 'Genuss', 'Wertschätzung', 'Verbundenheit', 'Attraktivität', 'Nächstenliebe'

        ],
        pictures:[
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Narr-Spaßvogel',
        description: 'Liebt es, sich und seine Umwelt zu bespaßen und somit eine gute Zeit zu verschaffen. Er ist verspielt und freiheitsliebend. Er möchte nicht, dass sein Umfeld ihn langweilig findet.',
        kategorieBild: "assets\\cards-pictures\\helge_schneider_card.jpg",
        attributes:['Humor', 'Lebensfreude', 'Fröhlichkeit', 'Spaß', 'Begeisterung', 'Glück'

        ],
        pictures:[
          
        ]
      }
    )

    this.archetypes.push(
      {
        name: 'Unschuldige-Ehrliche',
        description: 'Hat Angst davor, etwas falsch zu machen und infolge verlassen zu werden. Möchte stattdessen geliebt werden, Teil von etwas sein und im Hier und Jetzt glücklich sein. Er verhält sich regelkonform und positiv.',
        kategorieBild: "assets\\cards-pictures\\pippi_langstrumpf_card.jpg",
        attributes:['Ehrlichkeit', 'Dazugehörigkeit', 'Richtigkeit', 'Harmonie', 'Frieden', 'Akzeptanz', 'Anerkennung', 
          'Konformität'
          

        ],
        pictures:[
          
        ]
      }
    )
    this.archetypes.push(
      {
        name: 'Weise',
        description: 'Möchte die Welt verstehen und die Wahrheit erkennen. Um dieses Ziel zu erreichen, setzt er seine Intelligenz und seinen Verstand ein. Er möchte Bewusstsein für sich und die Welt erreichen. Der Weise lehnt Ignoranz ab und vermeidet es, falschen Lehrern und Verführern aufzusitzen.',
        kategorieBild: "assets\\cards-pictures\\dumbledore_card.jpg",
        attributes:['Weisheit', 'Klugheit', 'Weltkenntnis', 'Bildung', 'Reife', 'Weitblick', 'Denkkraft', 'Erfahrung', 'Vertrautheit'
          

        ],
        pictures:[
          
        ]
      }
    )

    this.archetypes.push(
      {
        name: 'Entdecker',
        description: 'Möchte sich und die Welt auf eine abenteuerliche Art erkunden und dabei ein besseres, authentisches und erfüllteres Leben finden. Er hat Angst vor innerer Lehre und lehnt Konformität sowie Enge ab.',
        kategorieBild: "assets\\cards-pictures\\albert_einstein_card.jpg",
        attributes:['Abenteuerlichkeit', 'Authentizität', 'Aktivität', 'Aufgeschlossenheit', 'Flexibilität', 'Freiheit', 
        'Unabhängigkeit', 'Ungebundenheit', 'Eigenständigkeit', 'Selbstbestimmtheit'

        ],
        pictures:[
          
        ]
      }
    )




  }

  getAllArchetypes(){
    return this.archetypes;
  }

  getArchetypesByName(archetype){
    let archetypeByname;
    this.archetypes.forEach(element => {
      if(element.name == archetype){
        archetypeByname = element;
      }
      
    });
    return archetypeByname;
  }
}

export enum Archetype {
  Schoepfer = 1,
  BeschuetzerFuersorgende,
  Herrscher,
  Held,
  Rebell,
  Magier,
  Durschnittstyp,
  Liebhaber,
  NarrSpaßvogel,
  UnschuldigeEhrliche,
  Entdecker,
  Weise
}
