import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArchetypeComponent } from './archetype/archetype.component';
import { FaceanalysisComponent } from './faceanalysis/faceanalysis.component';
import {ButtonModule} from 'primeng/button'
import {StepsModule} from 'primeng/steps';
import {MenuItem} from 'primeng/api';
import {CardModule} from 'primeng/card';
import {ImageModule} from 'primeng/image';
import {CarouselModule} from 'primeng/carousel';
import { ArchetypeTestComponent } from './archetype/archetype-test/archetype-test.component';
import { SelfDecisionComponent } from './archetype/self-decision/self-decision.component';
import { PictureHandlerService } from './services/picture-handler.service';
import { GlassesHandlerService } from './services/glasses-handler.service';
import { ArchetypeHandlerService } from './services/archetype-handler.service';
import { YourGlassesComponent } from './your-glasses/your-glasses.component';
import { ArchetypeResultComponent } from './archetype-result/archetype-result.component';

import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ArchetypeHomeComponent } from './archetype/archetype-home/archetype-home.component';
import { ArchetypeInformationsComponent } from './archetype-informations/archetype-informations.component';
import {MatToolbarModule} from '@angular/material/toolbar'; 
import {MatRadioModule} from '@angular/material/radio';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AttributesComponent } from './archetype/attributes/attributes.component'; 


@NgModule({
  declarations: [
    AppComponent,
    ArchetypeComponent,
    FaceanalysisComponent,
    ArchetypeTestComponent,
    SelfDecisionComponent,
    YourGlassesComponent,
    ArchetypeResultComponent,
    ArchetypeHomeComponent,
    ArchetypeInformationsComponent,
    AttributesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    StepsModule,
    CardModule,
    ImageModule,
    CarouselModule,
    BrowserModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatRadioModule,
    BrowserAnimationsModule
  ],
  providers: [PictureHandlerService, GlassesHandlerService, ArchetypeHandlerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
