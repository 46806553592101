 <div class="grid">

    <div id="footer-space"></div>

    <p class="font-bold p-3">Wählen Sie drei passende Attribute {{counter}}/3</p>
  
    <div class="flex col-12 col-offset-0 sm justify-content-center">  
          
            <div class="grid mt-5 border-round-bottom  font-bold p-3 flex align-items-center justify-content-center">

                <div class="col-5 allign-items-center justify-content-center font-bold flex border-round" id="word" *ngFor="let word of words" [class.highlighted]="selection.indexOf(word) !== -1" (click)="onClick(word)">
                        <p>{{word}}</p>
                </div>

                <div class="flex col-12 sm justify-content-end">
                <button type="button" *ngIf="next()" class="p-button-rounded p-button-success float-right" (click)="continue()" pButton label="Weiter" routerLink="../archetype-test" routerLinkActive="active">
                  <mat-icon>done</mat-icon>
                </button>
                </div>
                
            </div>                
  


  
      </div>
</div>


<!--  
                  <button  type="button" *ngIf="next()" class="btn btn-primary shadow-none" (click)="continue()" pButton label="Weiter" routerLink="../archetype-test" routerLinkActive="active">
                  <mat-icon>done</mat-icon>
                </button>
-->