import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'opm-app';

  items: MenuItem[];

  ngOnInit() {
    this.items = [{
            label: 'Archetype',
            routerLink: 'archetype'
        },
        {
            label: 'Face Analysis',
            routerLink: 'faceanalysis'
        }
    ];
  }
}
