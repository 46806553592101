  <div class="grid">

    <div id="footer-space"></div>
  
    <div class="flex col-12 col-offset-0 sm justify-content-center">  
          <div class="grid flex flex-wrap">  
            <div class="grid">

                <div class="flex col-12"> <!-- col-8 col-offset-2 xl:col-12 sm -->
                  <!-- <p-steps [model]="items" [readonly]="true"></p-steps> -->
              
                <mat-toolbar class="toolbarNav">
                  <button type="mat-button" class="btn btn-primary shadow-none" pButton routerLink="/archetype/archetype-home" routerLinkActive="active">
                    <mat-icon>home</mat-icon>
                    </button>
                </mat-toolbar>
              
              
              
                  <router-outlet></router-outlet>
                </div>
              </div>                
            
            <div class="cards col-12 justify-content-center">
                <h2 class="header">
                  <!---->
                  <p-card class="flex col-12 col-offset-0 sm justify-content-center" header="{{currentArchetype.name}}" subheader=""  styleClass="p-card-shadow" >
                  <ng-template pTemplate="header">
                    <img alt="Card" src={{currentArchetype.kategorieBild}}>
                  </ng-template>
                  <p>{{currentArchetype.description}}</p>
                  <ng-template pTemplate="footer">
                    <div class="grid">
                    <div class="flex col-4 flex-wrap break-text" *ngFor="let item of glassesByArchetype">
                      <p-image src = "{{item.url}}" alt="Image" width="100%" [preview]="true"> </p-image>
                      <p class="brand-tag">{{item.brand}}</p>
                    </div>
                  </div>
                  <p class="margin-4" *ngIf="glassesByArchetype.length==0">Wir haben leider keine Modelle für Sie zur Auswahl, schauen Sie im Laden vorbei! </p>

                  </ng-template>
                  </p-card>
                  <!---->
                </h2>
            </div>
            

          </div>
  
        </div>


</div>