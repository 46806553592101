import { Component, OnInit } from '@angular/core';
import { GlassesHandlerService } from '../services/glasses-handler.service';

@Component({
  selector: 'app-your-glasses',
  templateUrl: './your-glasses.component.html',
  styleUrls: ['./your-glasses.component.scss']
})
export class YourGlassesComponent implements OnInit {

  currentArchetype;
  glassesByArchetype = [];
  printedArchetype;
  constructor(private glassesHandlerService:GlassesHandlerService) { 

  }

  public printArchetype(archetype){
    if(archetype.name == 'Narr-Spaßvogel'){
      this.printedArchetype = 'Narr , Spaßvogel';
    }else if(archetype.name == 'Beschuetzer/Fuersorgende'){
      this.printedArchetype = 'Beschützer , Fürsorgende';
    }else if(archetype.name == 'Schoepfer'){
      this.printedArchetype = 'Schöpfer';
    }else if(archetype.name == 'Unschuldige-Ehrliche'){
      this.printedArchetype = 'Unschuldige , Ehrliche';
    }else{
      this.printedArchetype = archetype.name;
    }
  }

  public showGlasses(gender,archetype){
    
  }

  public showGlassesByArchetype(archetype){
    let gender=JSON.parse(localStorage.getItem('gender'));
    let size = JSON.parse(localStorage.getItem('size'));
    this.glassesByArchetype = this.glassesHandlerService.getGlassesByGenderAndSizeAndArchetype(gender,size,this.currentArchetype.name);
  }



  ngOnInit(): void {
    this.currentArchetype = JSON.parse(localStorage.getItem('currentArchetype'));
    this.printArchetype(this.currentArchetype);
    this.showGlassesByArchetype(this.printedArchetype);

    console.log(this.glassesByArchetype);

    
  }


}
