<div class="grid">
      

    <div id="footer-space"></div>

    <div class="row">
        <div class="flex col-10 col-offset-1 sm">

            <h2>Herzlich Willkommen bei Spectacle Spectacles</h2>

        </div>
        <div class="flex col-10 col-offset-1 sm justify-content-center">

            <p class="subheader">Mit Spectacle Spectacles kannst du alle Brillen bequem auch auf dem Sofa anprobieren.
                Wähle Direktwahl, um einen von neun Persönlichkeitstypen auszuwählen. Falls du noch unsicher bist, 
                kannst du über "Zum Test" deinen geeigneten Typen selbst ermitteln.
            </p>

        </div>
        <div class="col-10 col-offset-1 sm justify-content-center">

            <img class="logo" src = "assets/logos-pictures/spectacle_spectacles_logo_render.png" alt="Image" width="100%">
            <img class="lettering" src = "assets/logos-pictures/spectacle_spectacles_lettering_dark.png" alt="Image" width="100%">

        </div>
    </div>

</div>