import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-archetype',
  templateUrl: './archetype.component.html',
  styleUrls: ['./archetype.component.scss']
})
export class ArchetypeComponent implements OnInit {

  constructor() {}


  private countSelected: number;
  archetypePoints: [number, number][];
  picturePairs: [number, string][];
  activeTestPictures: [string, boolean][]

  picturePairsAnimal: [number, string[]][];
  picturePairsPerson: [number, string][][];
  picturePairsJob: [number, string][][];
  picturePairsCar: [number, string][][];
  picturePairsBrand: [number, string][][];
  picturePairsColor: [number, string][][];

  ngOnInit(): void {
    /*
    this.countSelected = 0;
    this.archetypePoints=[];
    for(let i=1;i<=Archetype.Weise;i++){
      this.archetypePoints.push([i,0]);
    }
    this.picturePairs=[];
    this.picturePairsAnimal=[];
    this.loadPictures();
    this.firstTest();
    */
  }
/*
  public itemClicked(path){
    //Verify if selected or unselected
    let type = this.getArchetypeFromPath(path);
    this.activeTestPictures.forEach(element => {
      if(element[0]==path){
        if(this.countSelected<=3){
          if(element[1]==false && this.countSelected<3){
            element[1] = true;
            this.countSelected++;
          }else if(element[1]==true){
            element[1]=false;
            this.countSelected--;
          }
        }
      }
    });
  }

  public decisionMade(types){
    types.forEach(element => {
      this.archetypePoints[element][1]++;
    });
    
  }

  //Animal Pictures
  public firstTest(){
    this.activeTestPictures =[]; //Max 9

    let filteredTypes = [1,2,3,4,5,6,7,8,10];
    filteredTypes.forEach(element => {
      this.activeTestPictures.push([this.picturePairsAnimal[element][1][0],false]);
    });
    return this.activeTestPictures;
  }

  getArchetypeFromPath(string){
    this.picturePairs.forEach(element => {
      if(string == element[1]){
        return element[0]
      }
    });
  }


  */
}
