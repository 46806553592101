<div class="grid">

      <div id="footer-space"></div>

    <div class="col-12 col-offset-0 sm">

        <mat-toolbar class="toolbarNav">
            <button type="mat-button" class="btn btn-primary shadow-none" pButton routerLink="/archetype/archetype-home" routerLinkActive="active">
              <mat-icon>home</mat-icon>
            </button>
          </mat-toolbar>

    <h2 class="first">
        <small>Weitere Angaben</small>
    </h2>


    <div>
        <p>Bitte wählen Sie ihr Geschlecht aus:</p>
        <mat-radio-group aria-label="Select an option">
            <mat-radio-button color="primary" (click)="gender('m')" value="1">Männlich</mat-radio-button>
            <mat-radio-button color="primary" (click)="gender('f')" value="2">Weiblich</mat-radio-button>
            <mat-radio-button color="primary" (click)="gender('d')" value="3">Divers</mat-radio-button>
        </mat-radio-group>
        <br>
        <br>
    </div>
      
    <div>
        <p>Bitte wählen Sie ihre Gesichtsgröße aus:</p>
        <mat-radio-group aria-label="Select an option">
            <mat-radio-button color="primary" (click)="face('s')" value="1">Klein</mat-radio-button>
            <mat-radio-button color="primary" (click)="face('b')" value="2">Groß</mat-radio-button>
            <mat-radio-button color="primary" (click)="face('u')" value="3">Unsicher</mat-radio-button>
        </mat-radio-group>
        <br>
        <br>
    </div>




    <div class="continue-button">
        <button pButton type="button" label="" class="p-button-rounded p-button-success" routerLink="../your-glasses">
            <mat-icon>done</mat-icon>
        </button>
    </div>

    </div>
</div>