import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArchetypeHomeComponent } from './archetype/archetype-home/archetype-home.component';
import { ArchetypeTestComponent } from './archetype/archetype-test/archetype-test.component';
import { ArchetypeComponent } from './archetype/archetype.component';
import { SelfDecisionComponent } from './archetype/self-decision/self-decision.component';
import { FaceanalysisComponent } from './faceanalysis/faceanalysis.component';
import { YourGlassesComponent } from './your-glasses/your-glasses.component';
import { ArchetypeInformationsComponent } from './archetype-informations/archetype-informations.component';
import { AttributesComponent } from './archetype/attributes/attributes.component';

const routes: Routes =  [
  { path: 'archetype', component: ArchetypeComponent,
  children: [
     { path: 'archetype-home', component: ArchetypeHomeComponent},    
     { path: 'archetype-test', component: ArchetypeTestComponent},
     { path: 'decision', component: SelfDecisionComponent},
     { path: 'attributes', component: AttributesComponent}
    ]},
  { path: 'faceanalysis', component: FaceanalysisComponent },
  { path: 'your-glasses', component: YourGlassesComponent },
  { path: 'archetype-informations', component: ArchetypeInformationsComponent},
  { path: '**', redirectTo: 'archetype/archetype-home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
