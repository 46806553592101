<div id="footer-space"></div>

<p>{{question}} {{countSelected}}/3</p>

    <div class="grid">

      <div class="flex col-12 col-offset-0 sm justify-content-center">

        <div class="grid flex flex-wrap">

          <div class="flex col-4 flex-wrap" *ngFor="let picture of currentPictures">

            <div class=img-container>
              <p-image [class.highlighted]="chosenPictures.indexOf(picture) !== -1" (click)="onClickPicture(picture)" src="{{picture.url}}" alt="{{picture.name}}" width="100%"></p-image>
            </div>
          </div>
          
          <div class="flex col-12 sm justify-content-end">
            <button pButton type="button" label="" class="p-button-rounded p-button-success float-right" (click)="checkNextCategory()">
              <mat-icon>done</mat-icon>
            </button>
          </div>

        </div>
    </div>
    </div>  

    <div id="footer-space">
      <div class="flex col-12 sm justify-content-end">
      </div>
  </div>
    



<!-- TESTING
<table class="fixed bottom-0 right-0">
  <tr>
    <th>Archetype</th>
    <th>Selected</th>
  </tr>
  <tr *ngFor="let item of pictureStates;let i=index">
    <td>{{i}}</td>
    <td>{{item}}</td>
  </tr>
</table>

<table class="fixed bottom-0 left-0">
  <tr>
    <th>Archetype</th>
    <th>Points</th>
  </tr>
  <tr *ngFor="let item of scorebaord;let i=index">
    <td>{{item.archetype}}</td>
    <td>{{item.score}}</td>
  </tr>
</table>
-->