import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss']
})
export class AttributesComponent implements OnInit {
  functional = ['schoepfer','herrscher','magier','durchschnittstyp','unschuldige-ehrliche','liebhaber','weise'];
  aesthetics = ['schoepfer','beschuetzer-fuersorgender','held','weise','entdecker'];
  emotional = ['rebell','durchschnittstyp','liebhaber','narr-spassvogel','unschuldige-ehrliche','entdecker'];
  ethically = ['schoepfer','beschuetzer-fuersorgender','held','rebell','magier','durchschnittstyp','narr-spassvogel','entdecker'];

  counter =0;
  nextPage = false;

  selection = [];
  words = ['Qualität', 'Pragmatik','Bildung', 'Ästhetik','Freundschaft', 'Abenteuer','Selbstverwirklichung', 'Verbundenheit'];

  constructor() { }

  ngOnInit(): void {
  }

 onClick(word){
  
  if(this.counter<3  && this.selection.includes(word) == false ){
    this.selection.push(word);
    this.counter++;
  }else if(this.counter <= 3){
    if(this.selection.includes(word) == true){
      this.selection.forEach((element,index)=>{
        if(element == word){
          this.selection.splice(index,1);
        }
      });
      this.counter--;
    }
  }
 }

 next(){
   if(this.counter == 3){
    this.nextPage = true;
   }else{
     this.nextPage = false;
   }
   return this.nextPage;
 }

 continue(){
   let archetypes = [];

   if(this.selection.includes(this.words[0]) || this.selection.includes(this.words[1])){
    archetypes = archetypes.concat(this.functional);
   }
   if(this.selection.includes(this.words[2]) || this.selection.includes(this.words[3])){
    archetypes = archetypes.concat(this.aesthetics);
   }
   if(this.selection.includes(this.words[4]) || this.selection.includes(this.words[5])){
    archetypes = archetypes.concat(this.emotional);
   }
   if(this.selection.includes(this.words[5]) || this.selection.includes(this.words[6])){
    archetypes = archetypes.concat(this.ethically);
   }

   let archetypesUnique = archetypes.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    })


    localStorage.setItem('archetypesAfterAttribute', JSON.stringify(archetypesUnique));


 }


}