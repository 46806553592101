import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-archetype-informations',
  templateUrl: './archetype-informations.component.html',
  styleUrls: ['./archetype-informations.component.scss']
})
export class ArchetypeInformationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public gender(gender){
    localStorage.setItem('gender',JSON.stringify(gender));
  }

  public face(size){
    localStorage.setItem('size',JSON.stringify(size));
  }



}
