<div class="grid">

  <div class="col-12 col-offset-0 sm">
    
  
  <router-outlet></router-outlet>

    <mat-toolbar class="toolbarNav">
      <button  type="button" class="btn btn-primary shadow-none" pButton label="Direktwahl" routerLink="decision" routerLinkActive="active"></button>
      <button type="mat-button" class="btn btn-primary shadow-none" pButton routerLink="archetype-home" routerLinkActive="active">
        <mat-icon>home</mat-icon>
      </button>
      <button  type="button" class="btn btn-primary shadow-none" pButton label="Zum Test" routerLink="attributes" routerLinkActive="active"></button>
    </mat-toolbar>
  

  </div>

</div>

