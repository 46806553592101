import { Component, OnInit } from '@angular/core';
import { ArchetypeHandlerService } from 'src/app/services/archetype-handler.service';
import { PictureHandlerService } from 'src/app/services/picture-handler.service';


@Component({
  selector: 'app-self-decision',
  templateUrl: './self-decision.component.html',
  styleUrls: ['./self-decision.component.scss']
})
export class SelfDecisionComponent implements OnInit {

  constructor(private archetypeHanderService: ArchetypeHandlerService, private pictureHandlerService:PictureHandlerService) { }

  public archetypes;
  public pictures;
  public printArchetypes = [];


  ngOnInit(): void {
    this.archetypes= this.archetypeHanderService.getAllArchetypes();
    this.print(this.archetypes);

  }

  public print(archetype){
    this.archetypes.forEach(element => {
      this.printArchetypes.push(Object.assign({},element));
    });

    this.printArchetypes.forEach(function(part, index) {
        if(this[index].name == 'Narr-Spaßvogel'){
          this[index].name = 'Narr , Spaßvogel'; 
        }else if(this[index].name == 'Beschuetzer/Fuersorgende'){
          this[index].name = 'Beschützer , Fürsorgende';
        }else if(this[index].name == 'Schoepfer'){
          this[index].name = 'Schöpfer';
        }else if(this[index].name == 'Unschuldige-Ehrliche'){
          this[index].name = 'Unschuldige , Ehrliche';
        }
    }, this.printArchetypes);
  }

  private onClickArchetype(archetype){
    if(archetype.name == 'Narr , Spaßvogel'){
      archetype.name = 'Narr-Spaßvogel';
    }else if(archetype.name == 'Beschützer , Fürsorgende'){
      archetype.name = 'Beschuetzer/Fuersorgende';
    }else if(archetype.name == 'Schöpfer'){
      archetype.name = 'Schoepfer';
    }else if(archetype.name == 'Unschuldige , Ehrliche'){
      archetype.name = 'Unschuldige-Ehrliche';
    }
    localStorage.setItem('currentArchetype',JSON.stringify(archetype));

  }

  public getArchetypePicture(archetype){
    this.pictures=this.pictureHandlerService.getPicturesByCategoryAndArchetype('person',archetype);
    return this.pictures[0].url;
  }


}
