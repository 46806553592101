import { Component, OnInit } from '@angular/core';
import { PictureHandlerService } from 'src/app/services/picture-handler.service';
import { Router } from '@angular/router';
import { ArchetypeHandlerService } from 'src/app/services/archetype-handler.service';
import { element } from 'protractor';

@Component({
  selector: 'app-archetype-test',
  templateUrl: './archetype-test.component.html',
  styleUrls: ['./archetype-test.component.scss']
})
export class ArchetypeTestComponent implements OnInit {

  public Archetype = Archetype;
  public State = State;
  currentPictures;
  pictureStates;
  currentCategory;
  countSelected = 0;
  categories;
  categoryIndex = 0;
  nextCategoryOK = false;
  chosenPictures = [];
  scorebaord = [];
  question;
  pictureStatesColor;


  constructor(private pictureHandlerService: PictureHandlerService,private archetypeHandlerService: ArchetypeHandlerService,
              private router: Router) {}

  //#############################################################
  //                          Init
  //#############################################################


  ngOnInit(): void {    
    //Init
    this.scorebaord = this.initScorebaord();
    this.currentPictures = [];
    this.pictureStates = Array(12);
    this.pictureStates.fill(State.Missing);
    this.categories = [
      //'values',
      'animal',
      'person',
      'job',
      'car',
      'brand',
      'color'
    ]      
    this.pictureStatesColor = [
      {color:'rot', state:State.Missing},
      {color:'weiss', state:State.Missing},
      {color:'violett', state:State.Missing},
      {color:'blau', state:State.Missing},
      {color:'grau', state:State.Missing},
      {color:'schwarz', state:State.Missing},
      {color:'gelb', state:State.Missing},
      {color:'gruen', state:State.Missing},
      {color:'braun', state:State.Missing},
      {color:'rosa', state:State.Missing}
    ]
    this.updatePage();
  }

  initScorebaord(){
    let scorebaord = [];
    for(let i =0;i<12;i++){
      let score = {
        archetype: i,
        score: 0
      }
      scorebaord.push(score);
    }
    localStorage.setItem('scoreboard',JSON.stringify(scorebaord));
    return scorebaord;
  }

  //#############################################################
  //                    Page Update
  //#############################################################
  private questionAsked(currentCategory){
    if(currentCategory == 'animal'){
      this.question = 'Welche der drei vorliegenden Tiere gefallen Ihnen persönlich am meisten?';
    }
    if(currentCategory == 'person'){
      this.question = 'Mit welchen drei der folgenden Persönlichkeiten symphatisieren Sie persönlich am ehesten?';
    }
    if(currentCategory == 'job'){
      this.question = 'Welche der drei folgenden Berufe würden Sie gerne einmal für einen Monat ausüben, wenn Sie könnten?';
    }
    if(currentCategory == 'car'){
      this.question = 'Welche der drei folgenden Fortbewegungsmittel sprechen Sie am meisten an?';
    }
    if(currentCategory == 'brand'){
      this.question = 'Mit welchen drei der folgenden Marken können Sie sich am ehesten identifizieren?';
    }
    if(currentCategory == 'color'){
      this.question = 'Wählen Sie drei ihrer liebsten Farben';
    }
  }

  private updatePage(){
    this.chosenPictures = [];
    this.currentPictures = [];
    this.pictureStates = Array(12);
    this.pictureStates.fill(State.Missing);
    this.scorebaord = JSON.parse(localStorage.getItem('scoreboard'));

    this.currentCategory = this.categories[this.categoryIndex];
    this.questionAsked(this.currentCategory);
    this.fillPictures(this.currentCategory);
  }

  private fillColor(){

  }

  private fillPictures(category){
    let filled = this.checkFilled();
    let allPictures = this.pictureHandlerService.getPicturesByCategory(category);
    let archetypePicturesBeschuetzer = [];
    let archetypePicturesDurchschnittstyp = [];
    let archetypePicturesEntdecker = [];
    let archetypePicturesHeld = [];
    let archetypePicturesHerrscher = [];
    let archetypePicturesLiebhaber = [];
    let archetypePicturesMagier = [];
    let archetypePicturesNarr = [];
    let archetypePicturesRebell = [];
    let archetypePicturesSchoepfer = [];
    let archetypePicturesUnschuldige = [];
    let archetypePicturesWeise = [];

   if(category=='color'){
    this.currentPictures = this.shuffle(allPictures); 
    return 'lul'
   }

   //if value.archetype == element of selection --> dann bilder erst laden

   let selectedArchetypes = JSON.parse(localStorage.getItem('archetypesAfterAttribute'));
   console.log(selectedArchetypes);

    while(!filled){
      if(this.pictureStates[Archetype.BeschuetzerFuersorgende]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'beschuetzer-fuersorgender'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesBeschuetzer.push(value);
          }
        })
        this.pictureStates[Archetype.BeschuetzerFuersorgende] = State.Exists;
      }
      if(this.pictureStates[Archetype.Durchschnittstyp]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'durchschnittstyp'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesDurchschnittstyp.push(value);
          }
        })
        this.pictureStates[Archetype.Durchschnittstyp] = State.Exists;
      }
      if(this.pictureStates[Archetype.Entdecker]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'entdecker'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesEntdecker.push(value);
          }
        })
        this.pictureStates[Archetype.Entdecker] = State.Exists;
      }
      if(this.pictureStates[Archetype.Held]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'held'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesHeld.push(value);
          }
        })
        this.pictureStates[Archetype.Held] = State.Exists;
      }
      if(this.pictureStates[Archetype.Herrscher]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'herrscher'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesHerrscher.push(value);
          }
        })
        this.pictureStates[Archetype.Herrscher] = State.Exists;
      }
      if(this.pictureStates[Archetype.Liebhaber]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'liebhaber'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesLiebhaber.push(value);
          }
        })
        this.pictureStates[Archetype.Liebhaber] = State.Exists;
      }
      if(this.pictureStates[Archetype.Magier]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'magier'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesMagier.push(value);
          }
        })
        this.pictureStates[Archetype.Magier] = State.Exists;
      }
      if(this.pictureStates[Archetype.NarrSpaßvogel]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'narr-spassvogel'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesNarr.push(value);
          }
        })
        this.pictureStates[Archetype.NarrSpaßvogel] = State.Exists;
      }
      if(this.pictureStates[Archetype.Rebell]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'rebell'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesRebell.push(value);
          }
        })
        this.pictureStates[Archetype.Rebell] = State.Exists;
      }
      if(this.pictureStates[Archetype.Schoepfer]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'schoepfer'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesSchoepfer.push(value);
          }
        })
        this.pictureStates[Archetype.Schoepfer] = State.Exists;
      }
      if(this.pictureStates[Archetype.UnschuldigeEhrliche]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'unschuldige-ehrliche'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesUnschuldige.push(value);
          }
        })
        this.pictureStates[Archetype.UnschuldigeEhrliche] = State.Exists;
      }
      if(this.pictureStates[Archetype.Weise]==false){
        allPictures.forEach(function(value){
          if(value.archetype == 'weise'  && selectedArchetypes.includes(value.archetype)){
            archetypePicturesWeise.push(value);
          }
        })
        this.pictureStates[Archetype.Weise] = State.Exists;
      }

      filled = this.checkFilled();
    }

    let finalPictures = [];
    if(archetypePicturesBeschuetzer.length!=0){
      finalPictures.push(archetypePicturesBeschuetzer[Math.floor(Math.random() * archetypePicturesBeschuetzer.length)]);
    }
    if(archetypePicturesDurchschnittstyp.length!=0){
      finalPictures.push(archetypePicturesDurchschnittstyp[Math.floor(Math.random() * archetypePicturesDurchschnittstyp.length)]);
    }
    if(archetypePicturesEntdecker.length!=0){
      finalPictures.push(archetypePicturesEntdecker[Math.floor(Math.random() * archetypePicturesEntdecker.length)]);
    }
    if(archetypePicturesHeld.length!=0){
      finalPictures.push(archetypePicturesHeld[Math.floor(Math.random() * archetypePicturesHeld.length)]);
    }
    if(archetypePicturesHerrscher.length!=0){
      finalPictures.push(archetypePicturesHerrscher[Math.floor(Math.random() * archetypePicturesHerrscher.length)]);
    }
    if(archetypePicturesLiebhaber.length!=0){
      finalPictures.push(archetypePicturesLiebhaber[Math.floor(Math.random() * archetypePicturesLiebhaber.length)]);
    }
    if(archetypePicturesMagier.length!=0){
      finalPictures.push(archetypePicturesMagier[Math.floor(Math.random() * archetypePicturesMagier.length)]);
    }
    if(archetypePicturesNarr.length!=0){
      finalPictures.push(archetypePicturesNarr[Math.floor(Math.random() * archetypePicturesNarr.length)]);
    }
    if(archetypePicturesRebell.length!=0){
      finalPictures.push(archetypePicturesRebell[Math.floor(Math.random() * archetypePicturesRebell.length)]);
    }
    if(archetypePicturesSchoepfer.length!=0){
      finalPictures.push(archetypePicturesSchoepfer[Math.floor(Math.random() * archetypePicturesSchoepfer.length)]);
    }
    if(archetypePicturesUnschuldige.length!=0){
      finalPictures.push(archetypePicturesUnschuldige[Math.floor(Math.random() * archetypePicturesUnschuldige.length)]);
    }
    if(archetypePicturesWeise.length!=0){
      finalPictures.push(archetypePicturesWeise[Math.floor(Math.random() * archetypePicturesWeise.length)]);
    }

    
    this.currentPictures = this.shuffle(finalPictures); 
    console.log(this.currentPictures);
  }

  //#############################################################
  //                    OnPicture Click
  //#############################################################

  private checkColorExists(pictureStates, color){
    let exists = false;
    pictureStates.forEach(element => {
      if(element.color==color){
        if(element.state==State.Missing){
          exists = false;
        }else if(element.state==State.Selected){
          exists = true;
        }
        
      }
    });
    return exists;
  };

  private setColorState(pictureStates, color, state){
    pictureStates.forEach(element => {
      if(element.color==color){
        element.state= state;
      }
    });
    return pictureStates;
  };

  onClickPicture(picture){
    
    /**
     * Sonderfall: Color
     */

    if(picture.category=='color'){


      if(this.countSelected<=3){
        if(this.checkColorExists(this.pictureStatesColor,picture.name)){
          this.pictureStatesColor = this.setColorState(this.pictureStatesColor,picture.name, State.Missing);
          this.chosenPictures.forEach((element,index)=>{
            if(element==picture) this.chosenPictures.splice(index,1);
          });
          this.countSelected--;
        }else if(!this.checkColorExists(this.pictureStatesColor,picture.name) && this.countSelected<3){
          this.pictureStatesColor = this.setColorState(this.pictureStatesColor,picture.name, State.Selected);
          this.chosenPictures.push(picture);
          this.countSelected++;
        }
      }
      return null;
    }

    /**
     * Ab hier kein Sonderfall mehr
     */
    let archetypeString = picture.archetype;
    let archetypeIndex = this.getArchetypeIndex(archetypeString);

    if(this.countSelected<=3){
      if(this.pictureStates[archetypeIndex] == State.Exists && this.countSelected<3){
        this.pictureStates[archetypeIndex] = State.Selected;
        this.chosenPictures.push(picture)
        this.countSelected++;
      }else if(this.pictureStates[archetypeIndex] == State.Selected){
        this.pictureStates[archetypeIndex] = State.Exists;

        this.chosenPictures.forEach((element,index)=>{
          if(element==picture) this.chosenPictures.splice(index,1);
        });

        this.countSelected--;
      }
    }
  }


    //#############################################################
  //                    Helper
  //#############################################################


  private getArchetypeIndex(archetypeString){
    if(archetypeString=='beschuetzer-fuersorgender'){
      return Archetype.BeschuetzerFuersorgende;
    }
    if(archetypeString=='durchschnittstyp'){
      return Archetype.Durchschnittstyp;
    }
    if(archetypeString=='entdecker'){
      return Archetype.Entdecker;
    }
    if(archetypeString=='held'){
      return Archetype.Held;
    }
    if(archetypeString=='herrscher'){
      return Archetype.Herrscher;
    }
    if(archetypeString=='liebhaber'){
      return Archetype.Liebhaber;
    }
    if(archetypeString=='magier'){
      return Archetype.Magier;
    }
    if(archetypeString=='narr-spassvogel'){
      return Archetype.NarrSpaßvogel;
    }
    if(archetypeString=='rebell'){
      return Archetype.Rebell;
    }
    if(archetypeString=='schoepfer'){
      return Archetype.Schoepfer;
    }
    if(archetypeString=='unschuldige-ehrliche'){
      return Archetype.UnschuldigeEhrliche;
    }
    if(archetypeString=='weise'){
      return Archetype.Weise;
    }else{
      console.log('Archetype undefined')
    }
  }

    //Fisher-Yates Shuffle
    private shuffle(array) {
      let currentIndex = array.length,  randomIndex;
    
      // While there remain elements to shuffle...
      while (currentIndex != 0) {
    
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
    
      return array;
    }

    private checkFilled(){
      let filled = true;
      this.pictureStates.forEach(element => {
        if(element == State.Missing){
          filled = false;
      }
      });
      return filled;
    }

  //#############################################################
  //                    Next Button Handling
  //#############################################################

  checkNextCategory(){
    if(this.countSelected==3){
      this.nextCategoryOK = true;
    }else{
      console.log('choose more');
    }
    this.nextCategory();
  }

  private nextCategory(){
    if(this.nextCategoryOK){
      if(this.categoryIndex < this.categories.length-1){
        this.categoryIndex++;
        this.currentCategory = this.categories[this.categoryIndex];
        this.saveChoice();
        this.updatePage();
      }else{
        this.saveChoice();
        this.testFinished();
      }
    }else{
      console.log('action not allowed');
    }

  }

  private saveChoice(){
    let scorebaord =JSON.parse(localStorage.getItem('scoreboard'));
    scorebaord.forEach(element => {
      this.chosenPictures.forEach(e =>{
        if(e.category == 'color'){
          e.archetype.forEach(archetypeElement => {
            if(this.getArchetypeIndex(archetypeElement) == element.archetype){
              element.score++;
            }
          });
        }else if(this.getArchetypeIndex(e.archetype) == element.archetype){
          element.score++;
        }
      });
    });
    localStorage.setItem('scoreboard',JSON.stringify(scorebaord));
    this.countSelected = 0;
    this.nextCategoryOK = false;
  }

  //#############################################################
  //                    Results
  //#############################################################

  
  private testFinished(){
    this.getResult();
    //this.router.navigate(['your-glasses']);
    this.router.navigate(['archetype-informations']);
  }
  
  private getResult(){
    let scoreboard = JSON.parse(localStorage.getItem('scoreboard'));
    let maximumIndex;
    let maxValue = 0;
    scoreboard.forEach(element => {
      if(element.score>maxValue){
        maximumIndex = element.archetype;
        maxValue = element.score;
      }
    });
    let currentArchetype;
    if(Archetype[maximumIndex] == 'BeschuetzerFuersorgende'){
      currentArchetype = 'Beschuetzer/Fuersorgende';
    }else if(Archetype[maximumIndex] == 'NarrSpaßvogel'){
      currentArchetype = 'Narr-Spaßvogel';
    }else{
      currentArchetype = Archetype[maximumIndex]
    }
    let curArchetype = this.archetypeHandlerService.getArchetypesByName(currentArchetype);
    localStorage.setItem('currentArchetype',JSON.stringify(curArchetype));
    
  }

}

  //#############################################################
  //                    Extern Enums
  //#############################################################


export enum Archetype {
  Schoepfer = 0,
  BeschuetzerFuersorgende,
  Herrscher,
  Held,
  Rebell,
  Magier,
  Durchschnittstyp,
  Liebhaber,
  NarrSpaßvogel,
  UnschuldigeEhrliche,
  Entdecker,
  Weise
}

export enum State {
  Missing = 0,
  Exists,
  Eliminated,
  Selected
}