<div class="grid">

    <div id="footer-space"></div>
    <div id="top"></div>

    <p-card class="flex col-12 col-offset-0 sm justify-content-center" header="{{archetype.name}}" subheader=""  styleClass="p-card-shadow" *ngFor="let archetype of printArchetypes">
        
    
        <ng-template pTemplate="header">
            <img alt="Card" src={{archetype.kategorieBild}}>
        </ng-template>
        <p>{{archetype.description}}</p>
        <ng-template pTemplate="footer">
            <div class="flex col-12 col-offset-0 sm justify-content-end">
                <p-button (click)="onClickArchetype(archetype)" routerLink="../../archetype-informations" label="Wählen" icon="pi pi-check"></p-button>
            </div>
        </ng-template>
   

    </p-card>

    <div class="flex col-12 sm justify-content-end">
        <a class="backtotop" href="/archetype/decision/#top">Zurück zum Anfang</a>
    </div>

</div>