import { Injectable } from '@angular/core';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class GlassesHandlerService {

  private allGlasses = [];

  constructor() { 
    this.loadGlasses();
  }

  glasses = {
    url:'',
    brand:'',
    type:'',
    category:'',
    gender:'',
    size:'',
    color:'',
    archetype:''
  }

  private createGlasses(url){
    const divide = url.split('/');
    const attributes = divide[2].split('_');
    const color = attributes[5];
    

    let glasses = {
      url:url,
      brand:attributes[0],
      type:attributes[1],
      category:attributes[2],
      gender:attributes[3],
      size:attributes[4],
      color:color
    }    
    return glasses;
  }

  public getAllGlasses(){
    return this.allGlasses;
  }

  public getGlassesByGenderAndSizeAndArchetype(gender,size,archetype){
    let archetypeLowerCase = archetype.toLowerCase();
    console.log(archetypeLowerCase);
    let category = '';
    if(archetypeLowerCase == "beschuetzer/fuersorgende"){
      category = "Casual";
    }
    if(archetypeLowerCase == "durchschnittstyp"){
      category = "Casual";
    }
    if(archetypeLowerCase == "entdecker"){
      category = "Business";
    }
    if(archetypeLowerCase == "held"){
      category = "Rocker";
    }
    if(archetypeLowerCase == "herrscher"){
      category = "Extravagant";
    }
    if(archetypeLowerCase == "liebhaber"){
      	category = "Extravagant";
    }
    if(archetypeLowerCase == "magier"){
      category = "Rocker";
    }
    if(archetypeLowerCase == "narr-spaßvogel"){
      category = "Extravagant";
    }
    if(archetypeLowerCase == "rebell"){
      category = "Rocker";
    }
    if(archetypeLowerCase == "schoepfer"){
      category = "Business";
    }
    if(archetypeLowerCase == "unschuldige-ehrliche"){
      category = "Casual";
    }
    if(archetypeLowerCase == "weise"){
      category = "Urban";
    }


    let genderAndSizeAndArchetype = [];

    console.log('gender: '+ gender);
    console.log('size: '+size);
    if(size == 'u' && gender == 'd'){
      this.allGlasses.forEach(element => {
        if(element.category == category){
          console.log('Gleich');
          genderAndSizeAndArchetype.push(element);
        }
      });
    }else if(gender == 'd'){
      this.allGlasses.forEach(element => {
        if(element.size == size && element.category == category){
          console.log('Gleich');
          genderAndSizeAndArchetype.push(element);
        }
      });
    }else if(size == 'u'){
      this.allGlasses.forEach(element => {
        if(element.gender == gender && element.category == category){
          console.log('Gleich');
          genderAndSizeAndArchetype.push(element);
        }
      });
    }else{
      this.allGlasses.forEach(element => {
        console.log('Übergeben: '+gender);
        console.log(element.gender);
        console.log('Übergeben: '+size);
        console.log(element.size);
        if(element.gender == gender){
          console.log('Gender gleich');
        }
        if(element.size == size){
          console.log('Size gleich');
        }
  
        if(element.gender == gender && element.size == size && element.category == category){
          console.log('Gleich');
          genderAndSizeAndArchetype.push(element);
        }
        console.log(genderAndSizeAndArchetype.length);
      });

    }
    return genderAndSizeAndArchetype;
  }

  public getGlassesByGenderAndArchetype(gender,archetype){
    let category = '';
    if(archetype == "beschuetzer-fuersorgender"){
      category = "Casual";
    }
    if(archetype == "durchschnittstyp"){
      category = "Casual";
    }
    if(archetype == "entdecker"){
      category = "Business";
    }
    if(archetype == "held"){
      category = "Rocker";
    }
    if(archetype == "herrscher"){
      category = "Extravagant";
    }
    if(archetype == "liebhaber"){
      	category = "Extravagant";
    }
    if(archetype == "magier"){
      category = "Rocker";
    }
    if(archetype == "narr-spassvogel"){
      category = "Extravagant";
    }
    if(archetype == "rebell"){
      category = "Rocker";
    }
    if(archetype == "schoepfer"){
      category = "Business";
    }
    if(archetype == "unschuldige-ehrliche"){
      category = "Casual";
    }
    if(archetype == "weise"){
      category = "Urban";
    }


    let genderAndArchetype = [];
    this.allGlasses.forEach(element => {
      if(element.gender == gender && element.category == category){
        genderAndArchetype.push(element);
      }
    });

    return genderAndArchetype;
  }

  public getGlassesByArchetype(archetype){
    let archetypeLowerCase = archetype.toLowerCase();
    console.log(archetypeLowerCase);
    let category = '';
    if(archetypeLowerCase == "beschuetzer-fuersorgender"){
      category = "Casual";
    }
    if(archetypeLowerCase == "durchschnittstyp"){
      category = "Casual";
    }
    if(archetypeLowerCase == "entdecker"){
      category = "Business";
    }
    if(archetypeLowerCase == "held"){
      category = "Rocker";
    }
    if(archetypeLowerCase == "herrscher"){
      category = "Extravagant";
    }
    if(archetypeLowerCase == "liebhaber"){
      	category = "Extravagant";
    }
    if(archetypeLowerCase == "magier"){
      category = "Rocker";
    }
    if(archetypeLowerCase == "narr-spassvogel"){
      category = "Extravagant";
    }
    if(archetypeLowerCase == "rebell"){
      category = "Rocker";
    }
    if(archetypeLowerCase == "schoepfer"){
      category = "Business";
    }
    if(archetypeLowerCase == "unschuldige-ehrliche"){
      category = "Casual";
    }
    if(archetypeLowerCase == "weise"){
      category = "Urban";
    }

    console.log(category);
    let archetypeGlasses = [];
    this.allGlasses.forEach(element => {
      if(element.category == category){
        archetypeGlasses.push(element);
      }
    });
    console.log(this.allGlasses);
    console.log(archetypeGlasses);

    return archetypeGlasses;
  }

  public loadGlasses(){
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Ahlem_Concorde_Glamour_m_s_WhiteGold.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Ahlem_Jaures_Urban_m_b_Gold-light.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Ahlem_JauresSmokedlight_Rocker_NA_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Ahlem_PlaceCollet_Business_m_s_WhiteGold.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Ahlem_PlaceDeLaModelaine_Glamour_m_s_PennyGold.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Apo_Flexi-fit_Urban_m_s_R223550.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Apo_Map23_Business_m_s_204451.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Apo_OriginalPilot_Rocker_m_s_Silver.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Balenciaga_BB0014O_Casual_f_b_1.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/BartonPerreira_Courtier_Casual_m_b_CHA.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/BartonPerreira_DOYEN_Extravagant_m_s_SIL-AMG.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/BartonPerreira_Hux_Casual_m_s_Cry.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/BartonPerreira_Princeton_Casual_f_b_CHA.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/BauschLomb_BL11012KGF_Rocker_f_s_8488.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Bolon_BL3039_Extravagnt_f_s_A30.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Bolon_BL3076_Urban_f_b_9.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Bolon_BL5056_Rocker_f_F10.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Bolon_BL7155_Urban_f_b_68.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Bolon_BT6001_Rocker_f_s_B10.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/BottegaVeneta_BV0220SK_Business_f_b_3.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Cartier_C86F758N_Business_f_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Cartier_CT0035RS_Glamour_m_b_2.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Cartier_CT0127S_Glamour_f_b_2.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Cartier_CT00160_Casual_m_b_6.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CelineParis_CL5010in_Business_f_b_53.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CelineParis_CL40187I_Extravagant_f_s_25A.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CelineParis_CL500911_Business_f_b_1.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Chanel_3411_Business_f_s_622.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Chanel_LC92067087_Glamour_m_b_501.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Chloe_CC006S_Glamour_f_s_2.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Chloe_CH0057O_Extravagant_f_b_5.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ChristianDior_TF04637724_Rocker_f_s_2080.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ChromeHearts_Instaounce_Rocker_m_s.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Coblens_30444-302-Getriebe_Urban_f_s_421.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Coblens_30447_Urban_m_s_titanium.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Coblens_40102-303_Urban_m_b_titanium.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Coblens_60108-509_Extravagant_f_b_321.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Coblens_60112Baumkrone_Urban_f_b_titanium.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Coblens_Dolde60110_Urban_f_b_titanium.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Coblens_Smutje40102-301_Glamour_m_b_titanium.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Colibri_Hailey_Urban_f_s_103.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Colibri_Mara_Business_f_s_18.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Colibri_Maria_Glamour_f_s_65.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Colibri_Samu_Casual_m_s_7M.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Colibri_Sanna_Glamour_f_s_37.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Colibri_Sienna_Casual_f_s_12.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Colibri_Sina_Glamour_f_s_3.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGossLondon_CGOP9768_Business_m_b_1.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGBB_Business_m_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGGB_Rocker_NA_b_4.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGOP_Business_m_b_1.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGOP_Extravagant_f_b_305.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGOP_Glamour_f_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGOP1304_Rocker_f_b_4.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGOP1319_Business_m_b_1.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGOP1362_Sportler_m_b_2.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_CGSN0811_Glamour_f_b_811.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_Kingsman_Business_m_s_KDT.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/CutlerAndGrossLondon_M1346_Extravagant_f_b_2.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/DieterFunk_Beowulf_Business_f_s_RG.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Dior_StellaireSU_Rocker_f_b_8080.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Dior_TF08454194_Business_m_s_NeoDiorRU.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Dita_DRX-2030-G-BLK-18K-59_Rocker_NA_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/DITA_DRX-2064-ABLK-SLV-55_Business_m_s_StatesmanThree.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/DITA_DRX-2073_Business_m_s_ASH.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ElieSaab_ES009S_Glamour_f_s_2M2Jo.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Eyevan_71E_Casual_f_s_3071.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Eyevan_538TI_Business_f_s_1102.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Eyevan_717W_Sportler_f_s_1310.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/EyewearByDavidBeckham_AcetateDB7025_Rocker_m_s.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Foremost_Map18_Business_m_s_203490.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Funk_Sobek_Rocker_NA_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/GarretLeightCalifornia_Royce_Casual_m_s_SDI.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/GarretLeightCalifornia_Winward_Casual_m_s_TD.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/GöttiSwitzerland_Dancy_Glamour_f_b_GLB_ASH.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/GöttiSwitzerland_DYNES_Extravagant_f_s_SLS-Flamingo.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/GöttiSwitzerland_ErnestBTG_Business_m_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Gucci_GG0844O_Glamour_m_b_4.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Gucci_GG0904S_Glamour_f_s_3.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Gucci_GG1036O_Casual_f_b_1.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Hickmann_HI6196_Extravagant_f_s_E01.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Hopkins_HUG_Business_f_s_5.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/HUG_McTell_Rocker_f_b_12.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ICB_Alpha_Sportler_m_b_black.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ICB_Carbon_Extravagant_f_s_Grafite.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ICB_StuartL_Sportler_m_s.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ICB_VitalyG_Urban_m_b_Simarine.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/IsabelMarant_IM0049GS_Extravagant_f_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/JimmyChoo_INFS_Glamour_f_s_DDBFQ.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Kingsmann_MP0847_Rocker_m_s_B.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/LindaFarrow_5930_Glamour_f_b_LFC4.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/LindaFarrowXRalph&Russo_Watson_Business_f_s_cat37259.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Lindberg652951_091F784_Sportler_f_s_10.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Lindberg658548_072F084_Business_m_b_10.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Lindberg658649_10010719_Business_m_b_u9.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Lindberg660048_0121059Y6600_Casual_f_s_u12.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/LindbergLex47_TB-145-Lex_Business_m_s_k25m.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Mapleton_1976_Urban_m_b_4.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/MoscotOriginalsNYC_Lemtosh_Casual_f_s_BLL.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/MoscotOriginalsNYC_Lemtosh_Casual_m_b_black.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/MoscotOriginalsNYC_Shindig_Urban_m_s_Black.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Mykita_Larsson_Sportler_f_s_1Q.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Mykita_Lessrim_Sportler_m_s_364.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Mykita_Valda_Extravagant_f_b_992.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Mykita_Walt_Sportler_m_s_84.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/MYKLewis_IDFST9_Rocker_m_s_593.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Oakley_HalfJacket_Sportler_m_s.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/OCCXEyewear_Liebende_Extravagant_f_b_14.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/OCCXEyewear_Moralische_Casual_m_s_9.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/OCCXEyewear_Tolerante_Sportler_m_s_4.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Prada_SS01T_Sportler_m_b_U61-144.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/RagBone_RNB7007_Urban_m_b_146.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Reiz_Kreis_Urban_f_s_17.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Reiz_Kugel_Casual_f_b_137.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Reiz_Welle_Urban_f_s_137.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Rolf_Catalina_Rocker_NA_b.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Rolf_Denver_Sportler_m_b_01B.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Rolf_Lissabon_Sportler_f_s_01B.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Rolf_Milano_Rocker_m_s_01B.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Rolf_S1_Casual_m_b_99.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Rolf_S1_Sportler_m_b_133.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Rolf_Veloce_Rocker_NA_b_92.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/RSP_11012KGF_Business_m_s_226724.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/RSP_SRO825_Urban_m_s_201723.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/SaintLaurentParis_SL293OPT_Glamour_m_s.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/SaintLaurentParis_SL397F_Casual_m_s_2.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Starck_SH3030_Urban_m_b_2.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/StarckBiotechParis_SH2060T_Sportler_f_s_5.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/StarckBiotechParis_SH2063T_Sportler_f_s_1.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/StarckBiotechParis_SH3048_Sportler_m_s_11.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/SuzyGlam_FacesTheFace_Business_f_b_rosewoodMt.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/TB_801-G-RGD_Casual_f_s_51.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Theo_Colcannon_Extravagant_m_b_44.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Theo_EyeWittness_Extravagant_f_s_311.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Theo_Mille82_Extravagant_m_b_13.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Theo_Mille84_Extravagant_m_b_4.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Theo_Mille84_Extravagant_m_b_10.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Theo_Piece_Extravagant_f_s_387.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Theo_Pilat_Extravagant_m_s_7184.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Theo_Tracing_Extravagant_m_b_293.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ThomBrown_TBX412-48-01_Glamour_m_s_BLK.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/ThomBrown_TBX421-A-01_Extravagant_m_s_BLK-GLD.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Tods_TO295_Business_m_b_16Q.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/Tods_TO5258_Business_f_b_52.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/TOF_TF711_Extravagant_m_b_25E.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/TomFord_TF885_Rocker_NA_b_01P.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/TomFord_TF5400_Business_m_s_52.JPG'));
    this.allGlasses.push(this.createGlasses('assets/glasses-pictures/TomFord_TF5767-B_Rocker_f_b_52.JPG'));
  }
}
