<div class="grid">

  <div class="flex col-12"> <!-- col-8 col-offset-2 xl:col-12 sm -->



    <router-outlet></router-outlet>
  </div>
</div>



